import React from 'react'
import { graphql} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout className="404-page" location={this.props.location} title={siteTitle}>
        <Seo title="404: Not Found" />
        <Sidebar />
        <div className="content">
            <h1>Not Found</h1>
            <p>Nothing to see here. Try the menu or a search</p>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
